<template>
  <div class="new-address-modal" data-t="new-address-modal">
    <AddNewAddress @handle-next-step="handleNextStep" />
  </div>
</template>

<script setup lang="ts">
import { useAddressesStore } from '../../stores/useAddressesStore'

const { refreshAddresses } = useAddressesStore()

const router = useRouter()

function handleNextStep() {
  refreshAddresses()
  router.replace({ query: {} })
}
</script>

<style scoped>
.new-address-modal {
  display: flex;
  justify-content: center;
  width: 596px;
  padding: var(--spacing-300);
}
</style>
